import Cookies from 'js-cookie';

export class TokenContainer {

    TOKEN_KEY = "selfset-token";
    BACKUP_TOKEN_KEY = "token";

    hasToken() {
        return null != this.getToken();
    }

    getToken() {
        return Cookies.get(this.TOKEN_KEY) ?? Cookies.get(this.BACKUP_TOKEN_KEY);
    }

    setToken(token) {
        Cookies.set(this.TOKEN_KEY, token, { expires: 365 });
    }

    clearToken() {
        Cookies.remove(this.TOKEN_KEY);
        Cookies.remove(this.BACKUP_TOKEN_KEY);
    }

}