import { ClientBase } from './base/ClientBase';
import { TokenContainer } from './base/TokenContainer';
import { AccountClient } from './AccountClient';
import { AuthClient } from './AuthClient';
import { JournalClient } from './JournalClient';

export const API = process.env.REACT_APP_API;

export const tokenContainer = new TokenContainer();
const base = new ClientBase(
    API,
    tokenContainer
);

export const accountClient = new AccountClient(base);
export const authClient = new AuthClient(base);
export const journalClient = new JournalClient(base);
//export const authClient = new AuthClient(API, base, tokenContainer);