import React from "react";
import { Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';
import { LoginForm } from './components/LoginForm';
import { RegistrationForm } from './components/RegistrationForm';
import { ForgotForm } from './components/ForgotForm';
import { LogoutForm } from './components/LogoutForm';

import './LoginPage.scss';

export class LoginPage extends React.Component {

  render() {
    return <div className="LoginPage">
      <center>
        <Box className="LoginPageMain">
          <img src="/logo/LogoWithBackground.png" id="LoginLogo" alt="logo" /><br />
          <Routes>
            <Route path="/" element={<LoginForm />} exact />
            <Route path="register" element={<RegistrationForm />} exact />
            <Route path="forgot" element={<ForgotForm />} exact />
            <Route path="logout" element={<LogoutForm />} exact />
          </Routes>
        </Box>
      </center>
    </div>;
  }
}
export default LoginPage;