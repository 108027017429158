export class AccountClient {

    constructor(
        clientBase
    ) {
        this.client = clientBase;
    }

    login(phone, password, callback) {
        this.client.sendRequest(
            "POST",
            "/users/login",
            {
                "phone": phone,
                "password": password
            },
            callback
        )
    }

    register(first, last, phone, password, callback) {
        this.client.sendRequest(
            "POST",
            "/users",
            {
                "phone": phone,
                "password": password,
                "first": first,
                "last": last
            },
            callback
        );
    }

}