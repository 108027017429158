export class JournalClient {
    constructor(
        clientBase
    ) {
        this.client = clientBase;
    }

    getBuckets(callback) {
        this.client.sendRequest(
            "GET",
            `/journals/all?${Date.now()}`,
            {},
            callback
        );
    }

    getBucketDaily(journalId, callback) {
        this.client.sendRequest(
            "GET",
            `/journals/${journalId}/daily?${Date.now()}`,
            {},
            callback
        )
    }

}
