export class ClientBase {

    constructor(
        api,
        tokenContainer
    ) {
        this.api = api;
        this.tokenContainer = tokenContainer;
    }

    sendRequest(method, endpoint, payload, callback) {
        var xhr = new XMLHttpRequest();
        xhr.open(method, `${this.api}${endpoint}`, true);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-Type", "application/json");
        if (this.tokenContainer.hasToken()) {
            const token = this.tokenContainer.getToken();
            xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        }
        xhr.onreadystatechange = function() {
            if (this.readyState === XMLHttpRequest.DONE) {
                if (this.response === undefined) {
                    callback(this.status, null);
                } else if (this.status === 401) {
                    followRedirect();
                } else if (this.status === 200) {
                    callback(this.status, JSON.parse(this.response));
                } else {
                    callback(this.status, this.response);
                }
            }
        }
        xhr.send(JSON.stringify(payload));
    }

    getRedirect() {
        return getRedirect();
    }

}

function followRedirect() {
    window.location.href = getRedirect();   
}

function getRedirect() {
    return "/login?redirect=" + encodeURIComponent(window.location.href); 
}