import React from "react"
import { Link } from 'react-router-dom';
import { accountClient, tokenContainer } from '../../../clients/ClientRegistry';
import { getFormattedPhoneNum, followRedirect } from '.././login.js';

export class RegistrationForm extends React.Component {

  state = {
    error: "",
    first: "",
    last: "",
    phone: "",
    password: ""
  }

  async handleSubmit(event) {
    event.preventDefault();
    accountClient.register(
      this.state.first,
      this.state.last,
      this.state.phone,
      this.state.password,
      this.callbackHandler.bind(this)
    );
  }

  async callbackHandler(status, resp) {
    if (resp === undefined) {
      this.setState({ error: "Error: could not complete registration" });
    } else if (status !== 200) {
      this.setState({ error: "Error: could not complete registration" });
    } else if ("error" in resp) {
      this.setState({ error: resp['error'] });
    } else {
      tokenContainer.setToken(resp['token']);
      followRedirect();
    }
  }

  render() {
    return <div className="Registration">
      <br />
      <form onSubmit={this.handleSubmit.bind(this)}>
        <span className="header">Registration</span><br />
        <span className="error">{this.state.error}</span><br />
        <input type="hidden" name="form_name" id="form_name" value="registration" />
        <input type="text" id="first" name="first" placeholder="First"
          value={this.state.first}
          onChange={event => this.setState({ first: event.target.value })}
          autoFocus />
        <input type="text" id="last" name="last" placeholder="Last"
          value={this.state.last}
          onChange={event => this.setState({ last: event.target.value })} />
        <br /><br /><br />
        <input type="tel" id="user" name="user" placeholder="Phone Number"
          value={this.state.phone}
          onChange={event => this.setState({ phone: getFormattedPhoneNum(event.target.value) })}
          autoFocus />
        <input type="password" id="pass" name="pass" placeholder="Password"
          value={this.state.password}
          onChange={event => this.setState({ password: event.target.value })} />

        <input type="submit" value="Register" id="registerButton" />

        <p className="footer">
          Already have an account?&nbsp;
          <Link to={`/login${window.location.search}`} className="strong link">Sign In</Link>
        </p>
      </form>
    </div>
  }
}