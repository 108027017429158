import React from "react"
import { Link } from 'react-router-dom';
import { getFormattedPhoneNum } from '.././login.js';

export class ForgotForm extends React.Component {
  state = {}
  render() {
    return <div className="Forgot">
      <br />
      <form onSubmit={this.handleSubmit}>
        <span className="header">Forgot Password</span><br />
        <span className="error">{this.state.error}</span><br />
        <input type="hidden" name="form_name" id="form_name" value="forgot" />
        <input type="tel" id="user" name="user" placeholder="Phone Number"
          value={this.state.phone}
          onChange={event => this.setState({ phone: getFormattedPhoneNum(event.target.value) })}
          autoFocus />
        <input type="submit" value="Reset Password" id="forgotButton" />

        <p className="footer">Don't have an account?&nbsp;
          <Link to={`/login/register${window.location.search}`} className="strong link">Sign Up</Link>
        </p><br />
        <p className="footer">
          <Link to={`/login${window.location.search}`} className="strong link">Back to login</Link>
        </p>
      </form>
    </div>
  }
}