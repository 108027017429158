import Cookies from 'js-cookie';

export class AuthClient {
    
    constructor(
        clientBase
    ) {
        this.client = clientBase;
    }

    logout(callback) {
        this.client.tokenContainer.clearToken()
        this.client.sendRequest(
            "POST",
            "/users/logout",
            {},
            callback
        )
    }

    authPing() {
        const token = Cookies.get("selfset-token") ?? Cookies.get("token");
        if (token == null || token === "") {
            return false;
        }
        var xhr = new XMLHttpRequest();
        xhr.open("GET", `${this.API}/users`, false);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        xhr.send();
        return xhr.status === 200;
    }
    
    isLoggedIn() {
        return this.authPing();
    }

    setToken(token) {
        return this.client.tokenContainer.setToken(token);
    }

    hasCredentials() {
        return this.client.tokenContainer.hasToken();
    }

    getRedirect() {
        return this.client.getRedirect();
    }

}
