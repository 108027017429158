

export function followRedirect() {
  var p = new URLSearchParams(window.location.search)
  const uri = p.get('redirect');
  if (uri == null) {
    window.location = "/";
  } else {
    window.location = uri;
  }
}

export function getFormattedPhoneNum(number) {
  let clean = number.replace(/\D/g, '');
  let partOne = clean.substring(0, 3);
  let partTwo = clean.substring(3, 6);
  let partThree = clean.substring(6, 10);
  let output = "";
  if (partOne.length > 0) {
    output += "(" + partOne;
  }
  if (partTwo.length > 0) {
    output += ") " + partTwo;
  }
  if (partThree.length > 0) {
    output += "-" + partThree;
  }
  return output;
}