import { Routes, Route } from "react-router-dom";
import Box from '@mui/material/Box';
import { LayoutScaffold } from "./LayoutScaffold";
import { BucketsPage } from "../../pages/buckets/BucketsPage";
import { EntriesPage } from "../../pages/entries/EntriesPage";
import { SettingsPage } from "../../pages/settings/SettingsPage";
import { HomePage } from "../../pages/home/HomePage";

export function MainApp(props) {
  return <Box className="MainApp" sx={{ paddingY: '70px', }}>
    <LayoutScaffold>
      <Routes>
        <Route path="/buckets/*" element={<BucketsPage />} />
        <Route path="/create/*" element="Create Page" />
        <Route path="/entries/*" element={<EntriesPage />} />
        <Route path="/settings/*" element={<SettingsPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<h3>Page doesn't exist</h3>} />
      </Routes>
    </LayoutScaffold>
  </Box>
}