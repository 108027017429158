import React from "react"
import { Link } from 'react-router-dom';
import { accountClient, tokenContainer } from '../../../clients/ClientRegistry';
import { getFormattedPhoneNum, followRedirect } from '.././login.js';

export class LoginForm extends React.Component {

  state = {
    error: "",
    phone: "",
    password: ""
  }

  async handleSubmit(event) {
    event.preventDefault();
    accountClient.login(
      this.state.phone,
      this.state.password,
      this.callback.bind(this)
    );
  }

  callback(status, resp) {
    if (resp === undefined) {
      this.setState({ error: "Error: could not complete login" });
    } else if (status !== 200) {
      this.setState({ error: "Error: could not complete login" });
    } else if ("error" in resp) {
      this.setState({ error: resp['error'] });
    } else {
      tokenContainer.setToken(resp['token']);
      followRedirect();
    }
  }

  render() {
    return <div className="Login">
      <br />
      <form onSubmit={this.handleSubmit.bind(this)}>
        <span className="error">{this.state.error}</span><br />
        <input type="hidden" name="form_name" id="form_name" value="login" />
        <input type="tel" id="user" name="user" placeholder="Phone Number"
          value={this.state.phone}
          onChange={event => this.setState({ phone: getFormattedPhoneNum(event.target.value) })}
          autoFocus />
        <input type="password" id="pass" name="pass" placeholder="Password"
          value={this.state.password}
          onChange={event => this.setState({ password: event.target.value })} />
        <input type="submit" value="Sign In" id="loginButton" />

        <p className="footer">Don't have an account?&nbsp;
          <Link to={`/login/register${window.location.search}`} className="strong link">Sign Up</Link>
        </p><br />
        <Link to={`/login/forgot${window.location.search}`} className="strong forgot link">Forgot Password</Link>
      </form>
    </div>
  }
}