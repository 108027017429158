import { useEffect, useState } from "react";
import { journalClient } from "../../clients/ClientRegistry";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { JournalDisplay } from "../../components/journal/JournalDisplay";

export function BucketsPage(props) {

    const [isMounted, setIsMounted] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [buckets, setBuckets] = useState({});
    const [bucketOrder, setBucketOrder] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        journalClient.getBuckets((status, resp) => {
            if (isMounted) {
                setIsLoading(false);
                if (status === 200) {
                    setBuckets(resp.journals);
                    setBucketOrder(resp.journalOrder);
                }
            }
        });
        return () => setIsMounted(false);
    }, [isMounted]);

    if (isLoading) {
        return <center>
            <CircularProgress size="70px" sx={{my: "50px"}} />
        </center>;
    }
    let displayArray = bucketOrder.map(
        id => <JournalDisplay key={id} id={id} name={buckets[id]} />
    )
    return <Box>
        <Typography variant="h5" component="div" gutterBottom sx={{m: '10px'}}>
            My Journals
        </Typography>
        {displayArray}
    </Box>;
}