import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Typography } from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

function timeUntilMidnight() {
  var ms = new Date().setHours(24,0,0,0) - new Date();
  let ss = parseInt((ms / 1000) % 60);
  if (ss < 10) ss = "0" + ss;
  let mm = parseInt((ms / 1000 / 60) % 60);
  if (mm < 10) mm = "0" + mm;
  let hh = parseInt((ms / 1000 / 60 / 60) % 24);
  if (hh < 10) hh = "0" + hh;
  return `${hh}:${mm}:${ss}`;
}

function calcTimePercent() {
  var ms = new Date().setHours(24,0,0,0) - new Date();
  return 100 - parseInt(100 * (ms / (24*60*60*1000)));
}

export function CountdownBanner() {

  const [time, setTime] = useState(timeUntilMidnight());
  const [timePercent, setTimePercent] = useState(timeUntilMidnight());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(timeUntilMidnight());
      setTimePercent(calcTimePercent())
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return <Card variant="outlined" 
    sx={{ maxWidth: 'calc(100vw - 20px)', m: '10px' }}
  >
    <CardContent>
      <Grid container spacing={2} sx={{alignItems: 'center' }}>
        <Grid item xs={8}>
          <BorderLinearProgress variant="determinate" value={parseInt(timePercent)} />
        </Grid>
        <Grid item xs={1}>
          <Typography sx={{mx: '5px'}}>{time}</Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>;
}