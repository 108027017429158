import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { API, journalClient } from "../../clients/ClientRegistry";

export function EntryViewer(props) {

  const [isMounted, setIsMounted] = useState(true);
  const [hasData, setHasData] = useState(false);
  const [title, setTitle] = useState("....");
  const [text, setText] = useState("");
  const [imgUrl, setImgUrl] = useState();

  useEffect(() => {
    journalClient.getBucketDaily(props.id, (status, resp) => {
      if (status === 200 && isMounted) {
        if (Object.keys(resp).length > 0) setHasData(true);
        if ('title' in resp) setTitle(resp.title);
        if ('text' in resp) setText(resp.text);
        if ('img' in resp) setImgUrl(resp.img);
      }
    })
    return () => setIsMounted(false);
  }, [props.id, isMounted])

  if (!hasData) return null;
  return (
    <Card sx={{ maxWidth: 'calc(100vw - 20px)', m: '10px' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            <AutoAwesomeIcon size="small" />
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={title}
        subheader={props.name}
      />
      <CardMedia
        component="img"
        image={`${API}${imgUrl}`}
        alt={"Daily image for journal: " + props.name}
      />
      { (text !== null && text.length > 0) ? 
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {text}
          </Typography>
        </CardContent>
      : null }
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      </CardActions>
    </Card>
  )
}