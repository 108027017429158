import React from "react";
import { PageHeader } from "../../components/partials/PageHeader";
import { MobileNav } from "../../components/partials/MobileNav";

export function LayoutScaffold(props) {
  return <div className="MobileSaffold">
    <PageHeader />
      {props.children}
    <MobileNav />
  </div>;
}