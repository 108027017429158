import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import CollectionsIcon from '@mui/icons-material/Collections';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import Paper from '@mui/material/Paper';

export function MobileNav() {

  const navigate = useNavigate();

  const [value, setValue] = useState(getPathId());    

  let path = window.location.pathname;
  useEffect(() => setValue(getPathId), [path]);

  return <Paper sx={{ position: 'fixed', bottom: 0, left: 1, right: 1, boxShadow: '1px -3px 8px #ddd'}} >
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
          setValue(newValue); //or change current view
      }}
    >
      <BottomNavigationAction sx={{ minWidth: '30px' }}
        icon={<HomeIcon sx={{ fontSize: '30px', minWidth: '30px' }} />}
        onClick={() => navigate('/')} />
      <BottomNavigationAction sx={{ minWidth: '30px' }}
        icon={<MenuBookIcon sx={{ fontSize: '30px', minWidth: '30px' }} />}
        onClick={() => navigate('/buckets')} />
      <BottomNavigationAction icon={<AddCircleIcon sx={{ fontSize: '50px', color: '#3a0088' }} />}
        onClick={() => navigate('/create')} />
      <BottomNavigationAction sx={{ minWidth: '30px' }}
        icon={<CollectionsIcon sx={{ fontSize: '30px', minWidth: '30px' }} />}
        onClick={() => navigate('/entries')} />
      <BottomNavigationAction sx={{ minWidth: '30px' }}
        icon={<SettingsIcon sx={{ fontSize: '30px', minWidth: '30px' }} />}
        onClick={() => navigate('/settings')} />
    </BottomNavigation>
  </Paper>

}

function getPathId() {
  const base = window.location.pathname.substring(1).split("/")[0];
  switch (base) {
    case "":
      return 0;
    case "buckets":
      return 1;
    case "entries":
      return 3;
    case "settings":
      return 4;
    default:
      return null;
  }
}