import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LandingPage } from './pages/landing/LandingPage';
import { LoginPage } from './pages/login/LoginPage';
import { MainApp } from './templates/main/MainApp';
import { tokenContainer } from './clients/ClientRegistry';


function App() {
  const isLoggedIn = tokenContainer.hasToken();
  return <BrowserRouter>
    <Routes>
      <Route path="/login/*" element={<LoginPage />}  />
      <Route path="/*" element={
        isLoggedIn ? <MainApp /> : <LandingPage />
      } />
    </Routes>
  </BrowserRouter>
}
export default App;